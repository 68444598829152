@import url('https://fonts.googleapis.com/css2?family=Comfortaa&family=Montserrat+Alternates:wght@300&family=Nunito&display=swap');

html,
body {
  /* padding: 1rem; */
  margin: 0;
  font-family: "Comfortaa", sans-serif;
  
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

b {
  color: black;
}

code {
  font-family: "Comfortaa", sans-serif;
}
