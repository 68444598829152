.sent > svg {
    color: white;
    background-color: green;
    margin-left: 8px;
    border-radius: 8px;
    padding: 2px;
  }
  
  .spinner {
    animation: loading 600ms infinite linear;
    transition: all 300ms ease;
    font-size: 26px;
  }
  
  @keyframes loading {
    0% {
      transform: rotate(0);
    }
}